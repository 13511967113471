import * as React from 'react';

import {SanityEditorial, SanitySeries, Maybe} from 'types/generated';

import SeriesTemplate from './index';

export default ({
  pageContext,
  location,
}: {
  pageContext: {
    series: SanitySeries;
    seriesArticles: [Maybe<SanityEditorial>];
    limit: number;
    currentPage: number;
    numPages: number;
    href: string;
  };
  location: Location;
}): React.ReactElement => (
  <SeriesTemplate
    series={pageContext.series}
    seriesArticles={pageContext.seriesArticles}
    currentPage={pageContext.currentPage}
    limit={pageContext.limit}
    numPages={pageContext.numPages}
    location={location}
  />
);
